import React from "react";
import { Link, useLocation } from "react-router-dom";

import { UserContext } from "../../Context/UserContext";
import SmallCalendar from "../Calendar/SmallCalendar";
import css from "./Sidebar.module.css";

export const HomeBar = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <SmallCalendar />
            <ul className={css.sidebarList}>
              <Link to="/" className={css.link}>
                <li
                  className={
                    pathname === "/"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                  Início
                </li>
              </Link>

              <Link to="/users" className={css.link}>
                <li
                  className={
                    pathname === "/users"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    account_box
                  </i>
                  Usuários
                </li>
              </Link>
            </ul>
          </div>
          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2022
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
