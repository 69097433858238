import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import css from "./DayView.module.css";
import { ClinicContext } from "../../Context/ClinicContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { newId } from "../../Utils/newId";
import { getUserFirebase } from "../../Utils/firebaseClinic";

export default function DayView() {
  const {
    daySelected,
    appointmentsDocDay,
    dispatchAppointmentsDocDay,
    docSelected,
  } = React.useContext(ClinicContext);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showSelectedAppointment, setShowSelectedAppointment] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [searchedUser, setSearchedUser] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState(0);
  const [selectedEndTime, setSelectedEndTime] = useState(1);
  const [appointmentStatus, setAppointmentStatus] = useState(
    selectedAppointment ? selectedAppointment.status : false
  );
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");

  let dayArray = [];
  for (var i = 1; i <= 24; i++) {
    dayArray.push(i + ":00");
    dayArray.push(i + ":30");
  }
  // dayArray.push(12 + ":30 am");
  // for (var i = 1; i <= 11; i++) {
  //   dayArray.push(i + ":00 am");
  //   dayArray.push(i + ":30 am");
  // }
  // dayArray.push(12 + ":00 pm");
  // for (var i = 1; i <= 11; i++) {
  //   dayArray.push(i + ":00 pm");
  //   dayArray.push(i + ":30 pm");
  // }
  // dayArray.push(12 + ":00 am");

  useEffect(() => {
    setSelectedUser(false);
    setSearchedUser(false);
    setSearchQuery("");
  }, [showAppointmentModal]);

  const Event = ({ row }) => {
    if (
      appointmentsDocDay.map((e) => dayArray.indexOf(e.end)).indexOf(row) >= 0
    ) {
      let evt =
        appointmentsDocDay[
          appointmentsDocDay.map((e) => dayArray.indexOf(e.end)).indexOf(row)
        ];
      return (
        <>
          <div
            className={css.cell}
            onClick={() => {
              setSelectedAppointment(evt);
              setAppointmentStatus(evt.status);
              setShowSelectedAppointment(true);
              setSelectedEndTime(dayArray[row]);
              setSelectedStartTime(
                dayArray[row - 1] ? dayArray[row - 1] : "24:30"
              );
            }}
          >
            <div
              className={evt.status ? css.eventConfirmed : css.event}
              style={{
                gridRow: `${dayArray.indexOf(evt.end) + 1}`,
                gridColumn: `${2}`,
              }}
            >
              {evt.description}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          className={css.cell}
          onClick={() => {
            handleAppointmentMoal(dayArray, row);
          }}
        ></div>
      );
    }
  };

  function handleAppointmentMoal(dayArray, row) {
    setShowAppointmentModal(true);
    setSelectedStartTime(dayArray[row - 1] ? dayArray[row - 1] : "24:30");
    setSelectedEndTime(dayArray[row]);
  }

  function addAppointment(u) {
    const newAppointment = {
      id: newId(),
      userId: u.id,
      userName: u.displayName,
      userEmail: u.email,
      description:
        u.displayName +
        ` | ${selectedStartTime} - ${selectedEndTime}` +
        " não confirmado",
      docName: docSelected.displayName,
      docEmail: docSelected.email,
      day: dayjs(daySelected).format("DD-MM-YY"),
      start: selectedStartTime,
      end: selectedEndTime,
      status: false,
      location: "",
      geo: "",
      categories: "",
    };
    dispatchAppointmentsDocDay({ type: "push", payload: newAppointment });
    setShowAppointmentModal(false);
  }

  function deleteAppointment() {
    dispatchAppointmentsDocDay({
      type: "delete",
      payload: selectedAppointment,
    });
    setShowSelectedAppointment(false);
  }

  function updateAppointment() {
    const updatedAppointment = {
      id: selectedAppointment.id,
      userId: selectedAppointment.userId,
      userName: selectedAppointment.userName,
      userEmail: selectedAppointment.userEmail,
      description: appointmentStatus
        ? selectedAppointment.userName +
          ` | ${selectedStartTime} - ${selectedEndTime}` +
          " confirmado"
        : selectedAppointment.userName +
          ` | ${selectedStartTime} - ${selectedEndTime}` +
          " não confirmado",
      docName: selectedAppointment.docName,
      docEmail: selectedAppointment.docEmail,
      day: selectedAppointment.day,
      start: selectedAppointment.start,
      end: selectedAppointment.end,
      status: appointmentStatus,
      location: "",
      geo: "",
      categories: "",
    };
    dispatchAppointmentsDocDay({
      type: "update",
      payload: updatedAppointment,
    });
    setShowSelectedAppointment(false);
  }

  function searchPat() {
    getUserFirebase(searchQuery).then(function (result) {
      if (result[0]) {
        setSearchedUser(result[0]);
      } else {
        alert("paciente não encontrado");
      }
    });
  }

  if (docSelected) {
    return (
      <div className={css.day}>
        <span className={css.timezone}>GMT-03</span>
        <div className={`${css.title} `}>
          <p className={css.day_title}>{dayjs(daySelected).format("dd")}</p>
          <p className={css.day_number}>{dayjs(daySelected).format("DD")}</p>
        </div>
        {dayArray.map((hour, row) => (
          <React.Fragment key={row}>
            <p className={css.hour}>{hour}</p>
            {appointmentsDocDay ? (
              <Event row={row} />
            ) : (
              <div
                className={css.cell}
                onClick={() => {
                  handleAppointmentMoal(dayArray, row);
                }}
              ></div>
            )}
          </React.Fragment>
        ))}

        {showAppointmentModal && (
          <OutsideAlerter setMenuButton={setShowAppointmentModal}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowAppointmentModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <input
                  value={searchQuery}
                  placeholder="Pesquisar paciente por email..."
                  onInput={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  id="header-search"
                  name="s"
                  autoComplete="off"
                />
                <button onClick={() => searchPat()} className="defaultButton">
                  Pesquisar
                </button>
                <h3>Paciente</h3>
                <div className={css.choose}>
                  {searchedUser && (
                    <div
                      className={
                        searchedUser === selectedUser
                          ? css.cardActive
                          : css.card
                      }
                      onClick={() => {
                        setSelectedUser(searchedUser);
                      }}
                    >
                      <small className={css.cardUser}>
                        {searchedUser.displayName}
                      </small>
                      <small className={css.cardUser}>
                        {searchedUser.email}
                      </small>
                    </div>
                  )}
                </div>

                <div className={css.schedule}>
                  Dia: {dayjs(daySelected).format("DD/MM/YY")}
                </div>
                <div className={css.schedule}>Início: {selectedStartTime}</div>
                <div className={css.schedule}>Término: {selectedEndTime}</div>

                <div className={css.footer}>
                  <button
                    type="submit"
                    className="saveButton"
                    onClick={() => addAppointment(selectedUser)}
                  >
                    Agendar
                  </button>
                </div>
              </div>
            </div>
          </OutsideAlerter>
        )}

        {showSelectedAppointment && (
          <OutsideAlerter setMenuButton={setShowSelectedAppointment}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedAppointment(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedAppointment.description}</h3>
                <div className={css.schedule}>
                  <p>Nome: {selectedAppointment.userName}</p>
                  <p>Email: {selectedAppointment.userEmail}</p>
                  <p>Médico: {selectedAppointment.docName}</p>
                  <p>Dia: {dayjs(daySelected).format("DD/MM/YY")}</p>
                  <p>Início: {selectedStartTime}</p>
                  <p>Término: {selectedEndTime}</p>
                  <div className={css.status}>
                    <p>Cofirmado? </p>
                    <button
                      className={
                        appointmentStatus
                          ? "defaultActiveButton"
                          : "defaultButton"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setAppointmentStatus(true);
                      }}
                    >
                      sim
                    </button>
                    <button
                      className={
                        !appointmentStatus
                          ? "defaultActiveButton"
                          : "defaultButton"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setAppointmentStatus(false);
                      }}
                    >
                      não
                    </button>
                  </div>
                </div>

                <div className={css.footer}>
                  <button
                    type="submit"
                    className="deleteButton"
                    onClick={() => {
                      deleteAppointment();
                    }}
                  >
                    Deletar
                  </button>
                  <button
                    type="submit"
                    className="saveButton"
                    onClick={() => {
                      updateAppointment();
                    }}
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
