import React, { useContext, useState } from "react";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";
import { ClinicContext } from "../../Context/ClinicContext";
import SmallCalendar from "../Calendar/SmallCalendar";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { getUserFirebase } from "../../Utils/firebaseClinic";

export const ScheduleSidebar = () => {
  const { userData } = useContext(UserContext);
  const { setDocSelected, docSelected } = useContext(ClinicContext);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");

  function searchDoc() {
    getUserFirebase(searchQuery).then(function (result) {
      if (result[0]) {
        setSelectedUser(result[0]);
      } else {
        alert("médico não encontrado");
      }
    });
  }

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <SmallCalendar />
            <ul className={css.sidebarList}>
              <li
                className={css.sidebarListItem}
                onClick={() => setShowDocsModal(true)}
              >
                {!docSelected ? (
                  <h4>Escolher Médico</h4>
                ) : (
                  <h4>{docSelected.displayName}</h4>
                )}
              </li>
            </ul>
          </div>
          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2022
          </div>
        </div>

        {showDocsModal && (
          <OutsideAlerter setMenuButton={setShowDocsModal}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowDocsModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <input
                  value={searchQuery}
                  placeholder="Pesquisar médico por email..."
                  onInput={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  id="header-search"
                  name="s"
                  autoComplete="off"
                />
                <button onClick={() => searchDoc()} className="defaultButton">
                  Pesquisar
                </button>
                <h3>Médicos</h3>
                <div className={css.choose}>
                  {selectedUser && (
                    <div
                      className={css.card}
                      onClick={() => {
                        setDocSelected(selectedUser);
                        setShowDocsModal(false);
                      }}
                    >
                      <small className={css.cardUser}>
                        {selectedUser.displayName}
                      </small>
                      <small className={css.cardUser}>
                        {selectedUser.email}
                      </small>
                    </div>
                  )}
                </div>

                <div className={css.footer}></div>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
