import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { RequireAuth } from "./Utils/RequireAuth";
import { Header } from "./Components/Layout/Header";
import { LeftMenu } from "./Components/Sidebar/LeftMenu";
import { Content } from "./Components/Layout/Content";
import { Home } from "./Components/Home";
import { Login } from "./Components/Login/Login";
import { LoginCreate } from "./Components/Login/LoginCreate";
import { UserPage } from "./Components/UserPage/UserPage";
import { Schedule } from "./Components/Schedule/Schedule";
import { Users } from "./Components/Users";
import { ClinicStorage } from "./Context/ClinicContext";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );
  return (
    <UserStorage>
      <ClinicStorage>
        <Routes>
          <Route path="login/*" element={<Login />} />
          <Route path="/register" element={<LoginCreate />} />
          <Route element={<RequireAuth />}>
            <Route element={<SidebarLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/user" element={<UserPage />} />
            </Route>
          </Route>
        </Routes>
      </ClinicStorage>
    </UserStorage>
  );
}

export default App;
