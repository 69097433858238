import React from "react";
import { useNavigate } from "react-router-dom";

import css from "./UserPage.module.css";
import { Header } from "../Layout/Header";
import { CardTrial } from "../Layout/CardTrial";

export const UserPage = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Header title="Home" description="User - Mairini" />
      <div>
        <CardTrial
          icon={"route"}
          title={"Treino 1"}
          onClick={() => navigate("/treino")}
        />
      </div>
    </section>
  );
};
