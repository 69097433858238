import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAl8-8pXC4m9fH_3PPmHmxBvYWt7l9hi_Y",
  authDomain: "mairini-ae3dd.firebaseapp.com",
  projectId: "mairini-ae3dd",
  storageBucket: "mairini-ae3dd.appspot.com",
  messagingSenderId: "789658206134",
  appId: "1:789658206134:web:959d8fb938fbdbdcdf0b17",
  measurementId: "G-CTF5B1BBQN",
};

const app = initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
  var authFirebase = getAuth();
  connectAuthEmulator(authFirebase, "http://localhost:9099");
  var dbFirebase = getFirestore();
  connectFirestoreEmulator(dbFirebase, "localhost", 8080);
} else {
  authFirebase = getAuth(app);
  dbFirebase = getFirestore(app);
}
export const auth = authFirebase;
export const db = dbFirebase;
