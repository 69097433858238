import React, { useContext } from "react";

import { ClinicContext } from "../../Context/ClinicContext";
import { Head } from "../../Utils/Head";
import DayView from "./DayView";
// import MonthView from "./MonthView";

export const Schedule = () => {
  const { view } = useContext(ClinicContext);

  return (
    <>
      <Head title="Agenda" description="Agenda - Mairini" />

      {view === "day" && <DayView />}
    </>
  );
};
