import React, { useState, useContext, useEffect, useReducer } from "react";
import dayjs from "dayjs";

import { UserContext } from "./UserContext";
import {
  getAppointmentsDayFirebase,
  getAppointmentsDocDayFirebase,
  getWaitingFirebase,
} from "../Utils/firebaseClinic";
import { savedAppointmentsDocDayReducer } from "./clinicReducer";

export const ClinicContext = React.createContext();

export const ClinicStorage = ({ children }) => {
  const { user } = useContext(UserContext);
  const [appointmentsDocDay, dispatchAppointmentsDocDay] = useReducer(
    savedAppointmentsDocDayReducer,
    []
  );
  const [appointmentsDay, setAppointmentsDay] = useState([]);
  const [waitingList, setWaitingList] = useState([]);
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(dayjs().month());
  const [daySelected, setDaySelected] = useState(dayjs());
  const [docSelected, setDocSelected] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState("day");
  const [loadingClinic, setLoadingClinic] = useState(null);

  useEffect(() => {
    if (user) {
      getAppointmentsDayFirebase(daySelected).then(function (result) {
        if (result) {
          setAppointmentsDay(result);
        } else {
          console.log("no appointmensDay result");
        }
      });
      getWaitingFirebase(dayjs(daySelected).format("DD-MM-YY")).then(function (
        result
      ) {
        if (result) {
          setWaitingList(result);
        } else {
          setWaitingList([]);
          console.log("no waiting result");
        }
      });
    }
    if (docSelected) {
      getAppointmentsDocDayFirebase(daySelected, docSelected.email).then(
        function (result) {
          if (result) {
            dispatchAppointmentsDocDay({ type: "init", payload: result });
          } else {
            console.log("no appointmentsDocDay result");
          }
          setLoadingClinic(false);
        }
      );
    }
  }, [user, docSelected, daySelected]);

  return (
    <ClinicContext.Provider
      value={{
        appointmentsDocDay,
        dispatchAppointmentsDocDay,
        appointmentsDay,
        setAppointmentsDay,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        docSelected,
        setDocSelected,
        showAppointmentModal,
        setShowAppointmentModal,
        selectedAppointment,
        setSelectedAppointment,
        view,
        setView,
        loadingClinic,
        setLoadingClinic,
        waitingList,
        setWaitingList,
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};
