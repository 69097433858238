import dayjs from "dayjs";

export function getMonth(month = dayjs().month()) {
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;

  // GET DAYS OF MATRIX
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export function getWeek() {
  const month = dayjs().month();
  const year = dayjs().year();
  const currentDay = dayjs();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;

  // GET DAYS OF MATRIX
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });

  // GET CURRENT WEEK
  let weekIdx = 0;
  daysMatrix.forEach((row, iRow) => {
    row.forEach((day) => {
      if (
        dayjs(day).format("DD-MM-YY") === dayjs(currentDay).format("DD-MM-YY")
      ) {
        weekIdx = iRow;
      }
    });
  });
  return daysMatrix[weekIdx];
}

export function getDaysMonth() {
  var daysInMonth = dayjs().daysInMonth();
  var arrDays = [];

  while (daysInMonth) {
    var current = dayjs().date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;
  }

  return arrDays;
}
