import {
  createAppointmentFirebase,
  deleteAppointmentFirebase,
  updateAppointmentFirebase,
} from "../Utils/firebaseClinic";

export const savedAppointmentsDocDayReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      createAppointmentFirebase(payload);
      return [...state, payload];
    case "update":
      updateAppointmentFirebase(payload);
      return state.map((cal) => (cal.id === payload.id ? payload : cal));
    case "delete":
      deleteAppointmentFirebase(payload);
      return state.filter((app) => app.id !== payload.id);
    default:
      throw new Error();
  }
};
