import dayjs from "dayjs";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// USER

export const getUserFirebase = async (email) => {
  const docRef = collection(db, "users");
  const q = query(docRef, where("email", "==", email));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`user ${email} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getUsersFirebase = async () => {
  const docRef = collection(db, "users");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const updateUserFirebase = async (user) => {
  const userDoc = doc(db, "users", user.id);
  await updateDoc(userDoc, {
    id: user.id,
    displayName: user.displayName,
    email: user.email,
    staff: user.staff,
    category: user.category,
  });
  console.log("user updated");
};

// APPOINTMENTS

export const getAppointmentsDayFirebase = async (day) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("day", "==", dayjs(day).format("DD-MM-YY")));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${dayjs(day).format("DD-MM-YY")} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsDocDayFirebase = async (day, docEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("day", "==", dayjs(day).format("DD-MM-YY")),
    where("docEmail", "==", docEmail)
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(
      `appointments ${dayjs(day).format("DD-MM-YY")} - ${docEmail} loaded`
    );
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsPatFirebase = async (userEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("userEmail", "==", userEmail));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${userEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const createAppointmentFirebase = async (appointment) => {
  await setDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    docName: appointment.docName,
    docEmail: appointment.docEmail,
    day: appointment.day,
    start: appointment.start,
    end: appointment.end,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log(`appointment ${appointment.title} created`);
};

export const updateAppointmentFirebase = async (appointment) => {
  await updateDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    docName: appointment.docName,
    docEmail: appointment.docEmail,
    day: appointment.day,
    start: appointment.start,
    end: appointment.end,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log("appointment updated");
};

export const deleteAppointmentFirebase = async (appointment) => {
  await deleteDoc(doc(db, "appointments", appointment.id));
  console.log("appointment deleted");
};

// WAITING

export const getWaitingFirebase = async (day) => {
  const docRef = collection(db, "clinic", "waiting", day);
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log(`waiting ${day} loaded`);
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const addWaitingFirebase = async (appointment) => {
  await setDoc(doc(db, "clinic", "waiting", appointment.day, appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    docEmail: appointment.docEmail,
    day: appointment.day,
    start: appointment.start,
    end: appointment.end,
    status: appointment.status,
    arrived: appointment.arrived,
  });
  console.log(`waiting ${appointment.id} created`);
};

export const delWaitingFirebase = async (appointment) => {
  await deleteDoc(
    doc(db, "clinic", "waiting", appointment.day, appointment.id)
  );
  console.log(`waitingList ${appointment.id} deleted`);
};
