import React, { useState } from "react";

import css from "./Home.module.css";
import {
  getAppointmentsPatFirebase,
  getUserFirebase,
  updateUserFirebase,
} from "../Utils/firebaseClinic";
import { Input } from "./Forms/Input";

export const Users = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [selectedUser, setSelectedUser] = useState(false);
  const [appointmentsUser, setAppointmentsUser] = useState([]);
  const [updateCategory, setUpdateCategory] = useState(false);

  function searchUser(e) {
    e.preventDefault();
    getUserFirebase(searchQuery).then(function (result) {
      if (result[0]) {
        setSelectedUser(result[0]);
      } else {
        alert("usuário não encontrado");
      }
    });
  }

  function handleUpdate(e) {
    e.preventDefault();
    let updateUser = {
      id: selectedUser.id,
      displayName: selectedUser.displayName,
      email: selectedUser.email,
      staff: selectedUser.staff,
      category: updateCategory,
    };
    setSelectedUser(updateUser);
    updateUserFirebase(updateUser);
    setUpdateCategory(false);
  }

  function getAppointmens() {
    getAppointmentsPatFirebase(selectedUser.email).then(function (result) {
      if (result[0]) {
        return setAppointmentsUser(result);
      } else {
        console.log("sem consultas");
      }
    });
  }

  return (
    <div className={css.content}>
      <h1>Usuários</h1>
      <form>
        <input
          value={searchQuery}
          placeholder="Pesquisar por email..."
          onInput={(e) => setSearchQuery(e.target.value)}
          type="text"
          id="header-search"
          name="s"
          autoComplete="off"
        />
        <button className="defaultButton" onClick={(e) => searchUser(e)}>
          Pesquisar
        </button>
      </form>
      {selectedUser.displayName && (
        <section>
          <h4>Dados do usuário</h4>
          <p>Nome: {selectedUser.displayName}</p>
          <p>Email: {selectedUser.email}</p>
          <div className={css.category}>
            <p>Categoria:</p>
            <button
              className={
                updateCategory
                  ? updateCategory === "patient"
                    ? "defaultActiveButton"
                    : "defaultButton"
                  : selectedUser.category === "patient"
                  ? "defaultActiveButton"
                  : "defaultButton"
              }
              onClick={() => setUpdateCategory("patient")}
            >
              patient
            </button>
            <button
              className={
                updateCategory
                  ? updateCategory === "doctor"
                    ? "defaultActiveButton"
                    : "defaultButton"
                  : selectedUser.category === "doctor"
                  ? "defaultActiveButton"
                  : "defaultButton"
              }
              onClick={() => setUpdateCategory("doctor")}
            >
              doctor
            </button>
          </div>
          {selectedUser.category === "doctor" && (
            <section>
              <h4>Credenciais do médico</h4>
              <h5>CRM</h5>
              <Input label="Número" />
              <Input label="Estado" />
            </section>
          )}
          <button
            className="saveButton"
            disabled={!updateCategory}
            onClick={(e) => handleUpdate(e)}
          >
            Atualizar
          </button>
        </section>
      )}
      {selectedUser.category === "patient" && (
        <section>
          <h4>Consultas</h4>
          {getAppointmens()}
          {appointmentsUser.map((app, i) => (
            <div key={app.id}>
              <h4>Consulta {i + 1}</h4>
              <p>Dia: {app.day}</p>
              <p>Doc: {app.docName}</p>
            </div>
          ))}
        </section>
      )}
    </div>
  );
};
