import React from "react";

import css from "./SmallCalendar.module.css";
import { getMonth } from "./getDate";
import dayjs from "dayjs";
import { ClinicContext } from "../../Context/ClinicContext";

export default function SmallCalendar() {
  const [currentMonth, setCurrentMonth] = React.useState(getMonth());
  const {
    daySelected,
    setDaySelected,
    setShowEventModal,
    smallCalendarMonth,
    setSmallCalendarMonth,
  } = React.useContext(ClinicContext);

  React.useEffect(() => {
    setCurrentMonth(getMonth(smallCalendarMonth));
  }, [smallCalendarMonth]);

  function handlePrevMonth() {
    setSmallCalendarMonth(smallCalendarMonth - 1);
  }

  function handleNextMonth() {
    setSmallCalendarMonth(smallCalendarMonth + 1);
  }

  function handleReset() {
    setSmallCalendarMonth(dayjs().month());
  }

  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    if (nowDay === currDay) {
      return css.currentDay;
    } else if (dayjs(daySelected).format(format) === currDay) {
      return css.selectedDay;
    } else {
      return "";
    }
  }

  return (
    <div className={css.smallcalendar}>
      <header className={css.header}>
        <h3>
          {dayjs(new Date(dayjs().year(), smallCalendarMonth)).format(
            "MMMM YYYY"
          )}
        </h3>
        <div className={css.arrowButtonDiv}>
          <button className={css.arrowButton}>
            <span
              className={`material-icons ${css.materialIcon}`}
              onClick={handlePrevMonth}
            >
              chevron_left
            </span>
          </button>
          <button onClick={handleReset} className={css.button}>
            T
          </button>
          <button className={css.arrowButton}>
            <span
              className={`material-icons ${css.materialIcon}`}
              onClick={handleNextMonth}
            >
              chevron_right
            </span>
          </button>
        </div>
      </header>
      <div className={css.smallCalendarCells}>
        {currentMonth[0].map((day, i) => (
          <span key={i} className={css.cell}>
            {day.format(`dd`).charAt(0)}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                className={`${css.cellButton} ${getDayClass(day)}`}
                onClick={() => {
                  setDaySelected(day);
                }}
              >
                <span className={css.cellText}>{day.format(`D`)}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
