import css from "./CardTrial.module.css";

export const CardTrial = (props) => {
  let className = css.container;
  if (props.isActive) {
    className += " " + css.selected;
  }

  return (
    <div className={className} onClick={props.isActive ? props.onClick : null}>
      <i className={`material-icons ${css.cardIcon}`}>{props.icon}</i>
      <h5 className={css.title}>{props.title}</h5>
    </div>
  );
};
