import React, { useContext, useState } from "react";
import dayjs from "dayjs";

import css from "./Home.module.css";
import { Head } from "../Utils/Head";
import { ClinicContext } from "../Context/ClinicContext";
import OutsideAlerter from "../Utils/OutsideAlerter";
import {
  addWaitingFirebase,
  delWaitingFirebase,
} from "../Utils/firebaseClinic";

export const Home = () => {
  const {
    daySelected,
    appointmentsDay,
    setAppointmentsDay,
    dispatchAppointmentsDocDay,
    waitingList,
    setWaitingList,
  } = useContext(ClinicContext);
  const [selectedAppointment, setSelectedAppointment] = useState(false);
  const [showSelectedAppointment, setShowSelectedAppointment] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState(
    selectedAppointment ? selectedAppointment.status : "sem status"
  );
  const [statusModal, setStatusModal] = useState(false);

  function deleteAppointment() {
    dispatchAppointmentsDocDay({
      type: "delete",
      payload: selectedAppointment,
    });
    setShowSelectedAppointment(false);
  }

  function updateAppointment() {
    const updatedAppointment = {
      id: selectedAppointment.id,
      userId: selectedAppointment.userId,
      userName: selectedAppointment.userName,
      userEmail: selectedAppointment.userEmail,
      description:
        selectedAppointment.userName +
        ` | ${selectedAppointment.start} - ${selectedAppointment.end} | ` +
        appointmentStatus,
      docName: selectedAppointment.docName,
      docEmail: selectedAppointment.docEmail,
      day: selectedAppointment.day,
      start: selectedAppointment.start,
      end: selectedAppointment.end,
      status: appointmentStatus,
      location: "",
      geo: "",
      categories: "",
    };
    let updatedAppointmentsDay = appointmentsDay.map((app) =>
      app.id === updatedAppointment.id ? updatedAppointment : app
    );
    setAppointmentsDay(updatedAppointmentsDay);
    dispatchAppointmentsDocDay({
      type: "update",
      payload: updatedAppointment,
    });
    let patientArrived = {
      id: selectedAppointment.id,
      userId: selectedAppointment.userId,
      userName: selectedAppointment.userName,
      docEmail: selectedAppointment.docEmail,
      day: selectedAppointment.day,
      start: selectedAppointment.start,
      end: selectedAppointment.end,
      status: selectedAppointment.status,
      arrived: dayjs().format("H:mm"),
    };
    if (appointmentStatus === "em atendimento") {
      let list = [...waitingList];
      list.push(patientArrived);
      setWaitingList(list);
      addWaitingFirebase(patientArrived);
    } else {
      let list = waitingList.filter(
        (w) => w.userId !== selectedAppointment.userId
      );
      setWaitingList(list);
      delWaitingFirebase(patientArrived);
    }

    setShowSelectedAppointment(false);
  }

  function getWaitingTime(arrived) {
    let startList = dayjs().format("H:mm").split(":");
    let arrivedList = arrived.split(":");
    let minutes1 = Number(startList[0]) * 60 + Number(startList[1]);
    let minutes2 = Number(arrivedList[0]) * 60 + Number(arrivedList[1]);
    let watingHours = (minutes1 - minutes2) / 60;
    let watingHoursList = String(watingHours).split(".");
    return (
      Number(watingHoursList[0]) +
      "h " +
      Math.round(
        Number(watingHoursList[1] ? "0." + watingHoursList[1] : 0.00000001) * 60
      ) +
      "min"
    );
  }

  function getStyle(status) {
    switch (status) {
      case "marcado e não confirmado":
        return css.cardItemOut;
      case "desmarcado pelo paciente":
        return css.cardItemOut;
      case "não compareceu":
        return css.cardItemOut;
      case "remarcado":
        return css.cardItemOut;
      case "aguardando":
        return css.cardItem;
      case "em atendimento":
        return css.cardItem;
      case "atendido":
        return css.cardItemOk;
      case "marcado e confirmado":
        return css.cardItemConfirmed;
      default:
        return;
    }
  }

  return (
    <>
      <Head title="Home" description="Home - Mairini" />
      <h4>Bem vindo à Clinica Mairini - Secretaria</h4>
      <div className={css.container}>
        <div className={css.card}>
          <h4>Consultas de {dayjs(daySelected).format("DD MMM YY")}</h4>

          {appointmentsDay.map((app) => (
            <p
              key={app.id}
              className={getStyle(app.status)}
              onClick={() => {
                setSelectedAppointment(app);
                setAppointmentStatus(app.status);
                setShowSelectedAppointment(true);
              }}
            >
              {app.description}
            </p>
          ))}
        </div>

        <div className={css.card}>
          <h4>Espera</h4>
          <table>
            <thead>
              <tr>
                <th>Hora</th>
                <th>Chegada</th>
                <th>Paciente</th>
                <th>Tempo de espera</th>
              </tr>
            </thead>
            <tbody>
              {waitingList.map((app) => (
                <tr key={app.id}>
                  <td>{app.start}</td>
                  <td>{app.arrived}</td>
                  <td>{app.userName}</td>
                  <td>{getWaitingTime(app.arrived)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showSelectedAppointment && (
          <OutsideAlerter setMenuButton={setShowSelectedAppointment}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedAppointment(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedAppointment.description}</h3>
                <div className={css.schedule}>
                  <p>Nome: {selectedAppointment.userName}</p>
                  <p>Email: {selectedAppointment.userEmail}</p>
                  <p>Médico: {selectedAppointment.docName}</p>
                  <p>Dia: {dayjs(daySelected).format("DD/MM/YY")}</p>
                  <p>Início: {selectedAppointment.start}</p>
                  <p>Término: {selectedAppointment.end}</p>
                  <div className={css.status}>
                    <p>Status</p>
                    <div className={css.statusButton}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setStatusModal(true);
                        }}
                      >
                        {appointmentStatus}
                      </button>
                      <span
                        className={`material-icons ${css.materialIconStatus}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setStatusModal(true);
                        }}
                      >
                        expand_more
                      </span>
                    </div>
                    {statusModal && (
                      <OutsideAlerter setMenuButton={setStatusModal}>
                        <nav className={css.statusModal}>
                          <div className={css.statusBody}>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("aguardando");
                              }}
                            >
                              aguardando
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("atendido");
                              }}
                            >
                              atendido
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus(
                                  "desmarcado pelo paciente"
                                );
                              }}
                            >
                              desmarcado pelo paciente
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("em atendimento");
                              }}
                            >
                              em atendimento
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("marcado e confirmado");
                              }}
                            >
                              marcado e confirmado
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus(
                                  "marcado e não confirmado"
                                );
                              }}
                            >
                              marcado e não confirmado
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("não compareceu");
                              }}
                            >
                              não compareceu
                            </button>
                            <button
                              onClick={() => {
                                setStatusModal(false);
                                setAppointmentStatus("remarcado");
                              }}
                            >
                              remarcado
                            </button>
                          </div>
                        </nav>
                      </OutsideAlerter>
                    )}
                  </div>
                </div>

                <div className={css.footer}>
                  <button
                    type="submit"
                    className="deleteButton"
                    onClick={() => {
                      deleteAppointment();
                    }}
                  >
                    Deletar
                  </button>
                  <button
                    type="submit"
                    className="saveButton"
                    onClick={() => {
                      updateAppointment();
                    }}
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    </>
  );
};
