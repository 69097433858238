import css from "./leftmenu.module.css";
import { useLocation } from "react-router-dom";

import { ScheduleSidebar } from "./ScheduleSidebar";
import { HomeBar } from "./HomeSidebar";

export const LeftMenu = (props) => {
  const { pathname } = useLocation();

  let className = css.container;
  if (!props.isMenuVisible) {
    className = css.collapsed;
  }

  function getSidebar() {
    switch (pathname) {
      case "/":
        return <HomeBar />;
        break;
      case "/schedule":
        return <ScheduleSidebar />;
        break;
      default:
        return <HomeBar />;
    }
  }

  return <div className={className}>{getSidebar()}</div>;
};
